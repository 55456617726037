<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="9"
        md="9"
      >
        <g-form @submit="save">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body
              class="invoice-padding pt-0"
              style="margin-bottom: -30px;"
            >
              <!-- group type and trasnaction date & time section  -->
              <b-row class="invoice-spacing">

                <!-- transaction date  -->
                <b-col
                  v-if="!currentBranch.setDefaultDate"
                  md="2"
                >
                  <g-field
                    :value="getDate(selectedItem.transactionDate)"
                    label-text="date"
                    disabled
                    name="date"
                  />
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <g-picker
                    :value.sync="selectedItem.transactionDate"
                    label-text="date"
                    name="date"
                  />
                </b-col>

                <!-- transaction time  -->
                <b-col md="2">
                  <b-form-group>
                    <g-field
                      label-text="transactionTime"
                      name="transactionTime"
                      readonly
                      :value.sync="selectedItem.transactionTime"
                    />
                  </b-form-group>
                </b-col>

                <!-- group type selection -->
                <b-col
                  class="gb-package"
                  md="5"
                >
                  <label> {{ $t("applyFeesForGroupOfStudents") }} </label>
                  <b-button-group>
                    <b-button
                      :class="[
                        'gb-btn-child',
                        { btnActive: selectedGroupName === 'stage' },
                      ]"
                      variant="flat-primary"
                      @click="groupSelection('stage')"
                    >
                      {{ $t("stage") }}
                    </b-button>

                    <b-button
                      :class="[
                        'gb-btn-child',
                        { btnActive: selectedGroupName === 'row' },
                      ]"
                      variant="flat-primary"
                      @click="groupSelection('row')"
                    >
                      {{ $t("row") }}
                    </b-button>

                    <b-button
                      :class="[
                        'gb-btn-child',
                        { btnActive: selectedGroupName === 'class' },
                      ]"
                      variant="flat-primary"
                      @click="groupSelection('class')"
                    >
                      {{ $t("class") }}
                    </b-button>
                  </b-button-group>
                </b-col>

                <!-- group name  -->
                <b-col
                  v-if="selectedGroupName !== ''"
                  md="3"
                >
                  <b-form-group>
                    <g-field
                      field="select"
                      label="arabicName"
                      name="selectedGroup"
                      :label-text="selectedGroupName"
                      :options="selectedGroup"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      :value.sync="selectedItem.groupValueId"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- services section -->
            <b-card-body class="invoice-padding form-item-section">
              <b-row>
                <b-col cols="12">
                  <div class="d-none d-lg-flex">
                    <!-- headers -->
                    <b-row class="flex-grow-1 px-2 mb-50">
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t('#') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t('notes') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="4"
                        class="service-title"
                      >
                        {{ $t('service') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t('Price') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t('discounts') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t('priceAfterDiscount') }}
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div>
                </b-col>
              </b-row>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- services -->
                  <b-col cols="12">
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">

                        <!-- counter -->
                        <b-col
                          cols="12"
                          lg="1"
                          class="p-50"
                        >
                          <p class="mb-1 service-counter">
                            {{ index + 1 }}
                          </p>
                        </b-col>

                        <!-- notes -->
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.notes"
                            :short-desc="true"
                            size="sm"
                            class="my-0"
                            maxlength="500"
                          />
                          <small
                            class="textarea-counter-value float-right"
                          ><span class="char-count">{{
                            item.notes ? item.notes.length : 0
                          }}</span>
                            / 500
                          </small>
                        </b-col>

                        <!-- service -->
                        <b-col
                          cols="12"
                          lg="4"
                          class="service-feature"
                        >
                          <g-field
                            field="select"
                            rules="required"
                            :clearable="false"
                            :label="isRight ? 'arabicName' : 'englishName'"
                            :value.sync="item.id"
                            :dir="isRight ? 'rtl' : 'ltr'"
                            :options="itemsData"
                            :selectable="(service) => !service.stopped"
                            :placeholder="$t('selectService')"
                            @change="
                              (val) => {
                                item.serviceId = val.id;
                                item.price = val.serviceFees;
                                item.net = val.serviceFees;
                                item.total = val.serviceFees;
                                item.lineSerial = index + 1;
                                computeTaxAndPriceBeforeTax(item);
                                item.discountValue = val.discountValue;
                                item.discountPercentage = val.discountPercentage;
                                selectedItem.invoiceLines = invoiceData.items;
                                item.fixedPrecentage = val.isFeesFixed;
                              }
                            "
                          />
                        </b-col>

                        <!-- price -->
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.price"
                            type="number"
                            :disabled="item.fixedPrecentage === true"
                            class="mb-1"
                            @input="
                              (v) => {
                                computeTaxAndPriceBeforeTax(item);
                                computeTotal({ item: item });
                              }
                            "
                            @change="
                              (v) => {
                                computeTaxAndPriceBeforeTax(item);
                                computeTotal({ item: item });
                              }
                            "
                          />
                        </b-col>

                        <!-- discount -->
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.discountValue"
                            type="number"
                            class="mb-1"
                            @input="
                              (v) => {
                                computeTaxAndPriceBeforeTax(item);
                                computeTotal({
                                  item: item,
                                  discountValue: item.discountValue,
                                });
                              }
                            "
                          />
                        </b-col>

                        <!-- price after discount -->
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.priceBeforeTax"
                            type="number"
                            disabled
                            class="mb-1"
                          />
                        </b-col>
                      </b-row>

                      <!-- remove icon -->
                      <div
                        class="d-flex align-items-center border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="TrashIcon"
                          class="cursor-pointer"
                          stroke="firebrick"
                          @click="removeItem(index, item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <!-- add new service button -->
              <b-button
                v-if="!selectedItem.isConvertedToSale"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="addNewItemInItemForm"
              >
                {{ $t('addService') }}
                <feather-icon
                  icon="CloudLightningIcon"
                  size="15"
                  class="ml-25"
                />
              </b-button>
            </b-card-body>
          </b-card>
        </g-form>
      </b-col>

      <!-- Totals Section -->
      <b-col
        md="3"
        lg="3"
        class="d-flex flex-column"
      >
        <b-card class="border sticky-content-top">
          <b-row class="m-auto">
            <!-- invoice total value -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap mb-1">
                {{ $t('total') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.total)"
                placeholder="total"
                type="number"
                name="total"
                size="sm"
                disabled
              />
            </b-col>

            <!-- discount value -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap mb-1">
                {{ $t('discont') }}
              </span>
            </b-col>
            <b-col
              md="4"
              lg="4"
            >
              <g-field
                :value.sync="selectedItem.discountValue"
                :disabled="true"
                size="sm"
                type="number"
                :rules="`min_value:0|max_value:${selectedItem.total}`"
                placeholder="discountValue"
                name="discountValue"
                @input="
                  (v) => {
                    selectedItem.discountValue < 0 ||
                      selectedItem.discountValue > selectedItem.total
                      ? (v = 0)
                      : v;
                    selectedItem.discountValue = v;
                    selectedItem.discountPercentage = 0;
                    computeDiscount({ value: v });
                  }
                "
              />
            </b-col>

            <!-- discount percentage -->
            <b-col
              md="5"
              lg="5"
            >
              <g-field
                :value.sync="selectedItem.discountPercentage"
                :disabled="true"
                size="sm"
                placeholder="percentage"
                name="discountPercentage"
                type="number"
                rules="min_value:0|max_value:100"
                disable
                @input="
                  (v) => {
                    selectedItem.discountPercentage < 0 ||
                      selectedItem.discountPercentage > 100
                      ? (v = 0)
                      : v;
                    selectedItem.discountPercentage = v;
                    computeDiscount({ Percentage: v });
                  }
                "
              >
                <template #append>
                  <b-input-group-text> % </b-input-group-text>
                </template>
              </g-field>
            </b-col>

            <!-- net -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder mb-2">
                {{ $t('net') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.net)"
                size="sm"
                placeholder="net"
                type="number"
                name="Net"
                readonly
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="border sticky-content-top2"
        >
          <b-row>
            <!-- save button -->
            <b-button
              v-permission="'addDeservedFees'"
              data-action-type="save"
              class="mx-1 btn-block"
              variant="relief-primary"
              :disabled="selectedItem.invoiceLines.length <= 0"
              @click="save"
            >
              <feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-25"
              />
              {{ $t('save') }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// import Logo from '@core/layouts/components/Logo.vue';
import invoiceMixin from '@/mixin/invoiceMixin';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BFormGroup,
  VBToggle,
} from 'bootstrap-vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition, invoiceMixin, reportMixin],

  data() {
    return {
      currentLogo: localStorage.COMAPNY_LOGO,
      currentUser: localStorage.USERNAME,
      fullscreen: false,
      teleport: true,
      isCalculatingDiscount: false,
      template: '',
      selectedGroupName: '',
      students: {},
      pendingItem: {},
      items: [],
      errors: [],
      pendingItems: [],
      itemsData: [],
      selectedGroup: [],
      stages: [],
      rows: [],
      classRooms: [],
      selectedItem: {
        studentId: 0,
        groupId: 0,
        groupValueId: 0
      },
      invoiceData: {
        items: [
          {
            itemArabicName: '',
            itemEnglishName: '',
            serviceId: '',
            price: 0,
            total: 0,
            net: 0,
            fixedPrecentage: false,
            priceBeforeTax: 0,
            discountValue: 0,
            discountPercentage: 0,
          },
        ],
      },
    };
  },

  computed: {
    total() {
      return this.invoiceData.items.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
    },
  },

  watch: {
    fullscreen(newVal) {
      if (newVal === true) {
        document.getElementById('virticalmenu').classList.add('display');
        document.getElementById('navBar').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
        document.getElementById('navBar').classList.remove('display');
      }
    },
    'selectedItem.invoiceLines'(newVal, oldVal) {
      if (oldVal && oldVal.length > 0) this.selectedItem.invoicePayments = [];
    },
    total(newval) {
      this.selectedItem.total = newval;
      this.computeTotals();
    },
    'selectedItem.total'(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.students.discount) {
          this.computeDiscount({
            Percentage: this.selectedItem.discountPercentage,
          });
        } else {
          this.computeDiscount({ value: this.selectedItem.discountValue });
        }
      }
    },
  },

  mounted() {
    this.selectedItem.transactionTime = this.getTime();
    this.loadObj();
    this.hasClass();
    this.itemsSearchProvider();
    this.initTrHeight();
    this.computeTotals();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },

  methods: {
    itemsSearchProvider() {
      this.get({ url: 'services' }).then((data) => {
        if (this.id) {
          this.itemsData = data.filter(
            (val) => val.stopped === false || this.selectedItem.invoiceLines.indexOf((x) => x.serviceId === val.id) === -1
            );
        } else {
          this.itemsData = data.filter((val) => val.stopped === false);
        }
      });
    },
    hasClass() {
      if (this.fullscreen === true) {
        document.getElementById('virticalmenu').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
      }
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    toggleApi() {
      this.$fullscreen.toggle(this.$el.querySelector('.fullscreen-wrapper'), {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
      });
    },
    togglePendingItem() {
      if (
        this.selectedItem.invoiceLines && this.selectedItem.invoiceLines.length > 0
      ) {
        const pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
        this.pendingItems.push(pendingItem);
        this.doneAlert({
          title: this.$t('pendingSucess'),
          type: 'success',
        });
        this.getSelecteItemObj();
      } else {
        this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
      }
    },
    getSummary() {
      var totalPrice = 0;
      var totalDiscount = 0;
      this.selectedItem.discountValue = 0;
      this.selectedItem.discountPercentage = 0;
      this.selectedItem.invoiceLines.forEach((item) => {
        this.selectedItem.discountValue += parseFloat(item.discountValue) || 0;
        totalPrice += item.total;
        totalDiscount += parseFloat(item.discountValue) || 0;
      });
      this.selectedItem.discountPercentage = ((totalDiscount / totalPrice) * 100).toFixed(2);
    },
    computeTotal({ item, discountValue, discountPercentage }) {
      item.total = Number(item.price);
      item.net = Number(item.total);
      item.discountValue = Number(discountValue);
      if (discountPercentage > 0) {
        item.discountValue = (item.total * (discountPercentage / 100)).toFixed(
          2
        );
        item.net -= (item.total * discountPercentage) / 100;
      } else if (discountValue >= 0) {
        item.discountPercentage = ((discountValue / item.total) * 100).toFixed(
          2
        );
        item.discountValue = discountValue;
        item.net -= discountValue || 0;
        if (this.selectedItem.discountValue <= 0) {
          this.selectedItem.discountValue = 0;
          this.selectedItem.discountValue = discountValue || 0;
        } else {
          this.selectedItem.discountValue;
        }
      } else {
        item.net -= item.discountValue || 0;
      }
      this.getSummary();
    },
    computeTaxAndPriceBeforeTax(item) {
      if (item.discountValue > 0) {
        item.priceBeforeTax = (item.price - item.discountValue).toFixed(2);
      } else {
        item.priceBeforeTax = item.price;
      }
    },
    computeTotals() {
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.invoiceLines.forEach((invoice) => {
        this.selectedItem.netBeforeTaxes += (
            parseFloat(invoice.priceBeforeTax) - parseFloat(this.selectedItem.discountValue)
          ).toFixed(2);
      });

      // get discounted
      this.computeTotalDiscount();
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.total = 0;
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.computeTotalTaxes();
    },
    computeDiscount({ value, Percentage }) {
      if (!this.selectedItem.total) return;
      if (this.isCalculatingDiscount) return;
      this.isCalculatingDiscount = true;
      value = value || 0;
      Percentage = Percentage || 0;
      if (value) {
        this.selectedItem.discountPercentage = this.fraction(
          (value / parseFloat(this.selectedItem.total)) * 100
        );
      } else {
        this.selectedItem.discountValue = this.fraction(
          (parseFloat(this.selectedItem.total) * Percentage) / 100
        );
      }
      this.isCalculatingDiscount = false;
    },
    save() {
      // check
      if (!this.validateApplyForGroup()) return;
      if (!this.validateServices()) return;

      const groupName = this.selectedGroup.find((val) => val.id === this.selectedItem.groupValueId).arabicName;
      const studentsCountUrl = this.getUrlForGroup();
      this.get({ url: `${studentsCountUrl}?id=${this.selectedItem.groupValueId}` }).then((outCount) => {
        if (outCount === 0) {
          this.doneAlert({ text: `${this.$t('noStudentIn')} ${groupName}`, type: 'info' });
          return;
        }
        if (this.errors.length > 0) {
          this.doneAlert(this.errors[0]);
          return;
        }
        // maintain details
        this.invoiceData.items.forEach((element) => {
          if (!element.serviceId) {
            this.removeItem(element.lineSerial - 1, element);
          }
          this.selectedItem.invoiceLines = this.invoiceData.items;
        });
        // calling insert api
        const url = 'DeservedFees';
        this.create({ url, data: this.getModel() }).then(() => {
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          this.backToList();
        })
      })
    },
    backToList() {
      this.$router.push({ name: 'deservedFees' });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden';
      this.invoiceData.items.push({
        itemArabicName: '',
        itemEnglishName: '',
        serviceId: '',
        price: 0,
        total: 0,
        net: 0,
        notes: '',
        priceBeforeTax: 0,
        discountValue: 0,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(iex, item) {
      this.invoiceData.items.splice(iex, 1);
      this.selectedItem.discountValue -= !item.discountValue ? 0 : item.discountValue;
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      if (this.invoiceData.items.length <= 0) {
        this.selectedItem.invoiceLines = [];
        this.selectedItem.discountValue = 0;
        this.selectedItem.discountPercentage = 0;
      }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    loadObj() {
      this.getRows();
      this.getClassRoms();
      this.getStages();
    },
    getStages() {
      this.get({ url: 'Stages/getStagesLookup' }).then((data) => {
        this.stages = data;
      });
    },
    getRows() {
      this.get({ url: 'Rows/getRowsLookup' }).then((data) => {
        this.rows = data;
      });
    },
    getClassRoms() {
      this.get({ url: 'Classrooms/getClassesLookup' }).then((data) => {
        this.classRooms = data;
      });
    },
    groupSelection(groupName) {
      this.selectedGroupName = groupName;

      switch (groupName) {
        case 'stage':
          this.selectedGroup = this.stages;
          this.selectedItem.groupId = 1;
          break;

        case 'row':
          this.selectedGroup = this.rows;
          this.selectedItem.groupId = 2;
          break;

        case 'class':
          this.selectedGroup = this.classRooms;
          this.selectedItem.groupId = 3;
          break;

        default:
          this.selectedGroup = []
          this.selectedItem.groupId = 0
          break;
      }

      if (this.selectedGroup.length > 0) {
        this.selectedItem.groupValueId = this.selectedGroup[0].id;
      }
    },
    validateApplyForGroup() {
      if (this.selectedGroupName === '' || !this.selectedItem.groupValueId) {
        this.doneAlert({ text: this.$t('youMustSelectGroup'), type: 'warning' });
        return false;
      }
      return true;
    },
    validateServices() {
      for (let index = 0; index < this.selectedItem.invoiceLines.length; index++) {
        const element = this.selectedItem.invoiceLines[index];
        if (element.net < 0 || element.price <= 0) {
          this.doneAlert({ text: this.$t('serivcePriceMustBeMoreThanZeroInRowNo', { rowNo: index + 1 }), type: 'warning', timer: 4000 });
          return false;
        }
      }
      return true
    },
    getUrlForGroup() {
      switch (this.selectedGroupName) {
        case 'stage':
          return 'Stages/GetStudentsCountByStageId'

        case 'row':
          return 'Rows/GetStudentsCountByRowId'

        case 'class':
          return 'Classrooms/GetStudentsCountByClassroomId'

        default:
          return ''
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

@media (max-width: 768px) {
  .sticky-content-top span {
    margin-bottom: 10px;
  }
}

.service-title {
  width: min-content;
  background-color: #918fdf;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
}

.repeater-form .service-feature {
  padding-left: 3px;
  padding-right: 3px;
}

.repeater-form .service-counter {
  width: 20px;
  border: 1px solid;
  border-radius: 40%;
  text-align: center;
  background-color: antiquewhite;
  margin: 0 auto;
}
.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}
.gb-package label {
  font-size: 14px;
}
b-button-group {
  border-radius: 6px;
}
.gb-btn-child {
  border: 1px solid gainsboro;
}
.btnActive {
  background-color: rgba(115, 103, 240, 0.12);
}
</style>
